import { Cell, Distances } from "./";

export const computeDijkstraMap = (root: Cell): Distances => {
  const cells: Map<Cell, number> = new Map();
  cells.set(root, 0);

  const unvisited: Set<Cell> = new Set([root]);
  while (unvisited.size !== 0) {
    for (const cell of unvisited) {
      for (const linked of cell.links) {
        if (!cells.has(linked)) {
          cells.set(linked, cells.get(cell)! + 1);

          unvisited.add(linked);
        }
      }

      unvisited.delete(cell);
    }
  }

  return {
    root,
    cells,
  };
};

export const computePathTo = (goal: Cell, distances: Distances): Cell[] => {
  const breadcrumbs: Cell[] = [];

  let current = goal;
  while (current !== distances.root) {
    breadcrumbs.push(current);

    let bestNeighbour = current;
    let bestDistance = distances.cells.get(current)!;
    for (const cell of current.links) {
      if (distances.cells.get(cell)! < bestDistance) {
        bestDistance = distances.cells.get(cell)!;
        bestNeighbour = cell;
      }
    }

    current = bestNeighbour;
  }

  breadcrumbs.push(current);

  return breadcrumbs;
};

export const max = (distances: Distances): [Cell, number] => {
  let maxDistance = 0;
  let maxCell = distances.root;

  for (const [cell, d] of distances.cells) {
    if (d > maxDistance) {
      maxDistance = d;
      maxCell = cell;
    }
  }

  return [maxCell, maxDistance];
};
