import * as React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import css from "@styled-system/css";
import Maze from "../components/Maze";
import Controls from "../components/Controls";
import SEO from "../components/SEO";

const Container = styled.div(
  css({
    display: "flex",
    flexDirection: ["column-reverse", "row"],
    height: ["auto", "100vh"],
  }),
);

const CanvasContainer = styled.div(
  css({
    width: "100%",
    height: "100%",
    backgroundColor: "background",
    p: 2,
  }),
);

const ControlsContainer = styled.div(
  css({
    width: ["100%", "300px"],
    height: "100%",
    backgroundColor: "background",
    p: 2,
  }),
);

const Home = () => {
  return (
    <Layout>
      <SEO />

      <Container>
        <CanvasContainer className="canvas-container">
          <Maze />
        </CanvasContainer>
        <ControlsContainer className="controls-container">
          <Controls />
        </ControlsContainer>
      </Container>
    </Layout>
  );
};

export default Home;
