import * as React from "react";
import styled from "styled-components";
import css from "@styled-system/css";
import { colors } from "../styles";
import { Label } from "./Form";

export interface Props {
  title: string;
  name: string;
  checked: boolean;
  onChange: (value: string) => void;
}

const size = "16px";

const StyledSwitch = styled.div<{ checked: boolean }>(props =>
  css({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row-reverse",
    opacity: props.checked ? 1.0 : 0.8,
    transition: "all 250ms ease-in-out",

    label: {
      fontSize: 3,
      pb: 0,
    },

    "&:hover": {
      opacity: 1.0,
    },

    ".input": {
      position: "relative",
      display: "flex",
      alignItems: "center",

      label: {
        width: size,
        height: size,
        cursor: "pointer",
        position: "absolute",
        top: 0,
        left: 0,
        border: "solid 2px",
        borderColor: "primary",
        borderRadius: "4px",

        transition: "all 250ms ease-in-out",

        "&::after": {
          content: '""',
          width: "16px",
          height: "16px",
          borderRadius: "4px",
          position: "absolute",
          top: "-2px",
          left: "-2px",
          background: colors.primary,
          opacity: 0,
          transition: "all 250ms ease-in-out",
        },

        "&:hover": {
          opacity: 1.0,
        },
      },

      "input[type=checkbox]": {
        width: size,
        height: size,
        visibility: "hidden",
        m: 0,

        "&:checked + label": {
          opacity: 1,
          boxShadow: `0 0 2px ${colors.accent}, 0 0 5px ${colors.accent}`,
        },

        "&:checked + label::after": {
          opacity: 1,
        },
      },
    },
  }),
);

const Switch: React.FC<Props> = props => (
  <StyledSwitch checked={props.checked}>
    <Label htmlFor={props.name}>{props.title}</Label>
    <span className="input">
      <input
        type="checkbox"
        value="true"
        name={props.name}
        id={props.name}
        checked={props.checked}
        onChange={e => props.onChange(e.target.value)}
      />
      <label htmlFor={props.name}></label>
    </span>
  </StyledSwitch>
);

export default Switch;
