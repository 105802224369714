import * as React from "react";
import styled from "styled-components";
import css from "@styled-system/css";
import Button from "./Button";
import Input from "./Input";
import { Label, FormGroup } from "./Form";
import { useStore } from "../store";
import * as maze from "../maze";
import Select from "./Select";
import Switch from "./Switch";

const StyledControls = styled.div(css({}));

const SizeControls = styled.div(
  css({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    "div:first-child": {
      pr: 3,
    },

    input: {
      maxWidth: "6rem",
    },
  }),
);

function useFormInputValue(
  initialValue: any,
  onChange: (value: any) => void,
): [any, (value: any) => void] {
  const [value, setValue] = React.useState(initialValue);
  const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const v = parseInt(e.target.value, 10);

    setValue(isNaN(v) ? "" : v);
    onChange(isNaN(v) ? null : v);
  };

  return [value, _onChange];
}

const algoOptions: Array<{ text: string; value: maze.AlgoTypes }> = [
  { text: "Binary Tree", value: "binary" },
  { text: "Sidewinder", value: "sidewinder" },
];

const Controls = () => {
  const { options, actions } = useStore();

  const [numRows, onRowsChange] = useFormInputValue(options.numRows, v => {
    actions.updateOptions({ ...options, numRows: v });
  });
  const [numCols, onColsChange] = useFormInputValue(options.numCols, v => {
    actions.updateOptions({ ...options, numCols: v });
  });

  return (
    <StyledControls className="controls">
      <h1>make a maze</h1>

      <Button onClick={() => actions.generate()}>Generate</Button>

      <SizeControls>
        <FormGroup>
          <Label htmlFor="rows"># rows</Label>
          <Input
            value={numRows ?? undefined}
            min="1"
            max="100"
            placeholder="10"
            id="rows"
            name="rows"
            size={3}
            type="number"
            onChange={onRowsChange}
          />
        </FormGroup>

        <FormGroup>
          <Label htmlFor="cols"># cols</Label>
          <Input
            value={numCols ?? undefined}
            min="1"
            max="100"
            placeholder="10"
            id="cols"
            name="cols"
            size={3}
            type="number"
            onChange={onColsChange}
          />
        </FormGroup>
      </SizeControls>

      <FormGroup>
        <Select
          title="algorithm"
          options={algoOptions}
          selectedValue={options.algo}
          onSelect={(algo: any) => actions.updateOptions({ ...options, algo })}
        />
      </FormGroup>

      <FormGroup>
        <Switch
          title="show longest path"
          name="longest"
          checked={options.showLongestPath}
          onChange={() =>
            actions.updateOptions({
              ...options,
              showLongestPath: !options.showLongestPath,
            })
          }
        />
      </FormGroup>

      <FormGroup>
        <Switch
          title="show texture"
          name="texture"
          checked={options.showTexture}
          onChange={() =>
            actions.updateOptions({
              ...options,
              showTexture: !options.showTexture,
            })
          }
        />
      </FormGroup>
    </StyledControls>
  );
};

export default Controls;
