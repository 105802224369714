import * as React from "react";
import styled from "styled-components";
import css from "@styled-system/css";
import * as maze from "../maze";
import { colors } from "../styles";
import { useCanvasSize, lerpColor, hexToRgb } from "../utils";
import { useStore } from "../store";

const Canvas = styled.canvas(
  css({
    width: "100%",
    height: "100%",
  }),
);

const padding = 1;
const minCellSize = 4;
const maxCellSize = 80;
const pathScale = 0.14;

const xFn = (v: number): number => v + padding;
const yFn = (v: number): number => v + padding;

const line = (
  ctx: CanvasRenderingContext2D,
  x1: number,
  y1: number,
  x2: number,
  y2: number,
) => {
  ctx.beginPath();
  ctx.moveTo(x1, y1);
  ctx.lineTo(x2, y2);
  ctx.stroke();
};

const configureCanvas = (
  grid: maze.Grid,
  canvas: HTMLCanvasElement,
  ctx: CanvasRenderingContext2D,
  width: number,
  height: number,
  cellSize: number,
) => {
  const requiredHeight = Math.max(height, cellSize * grid.numRows + 10);

  canvas.width = width;
  canvas.height = requiredHeight;
  ctx.clearRect(0, 0, canvas.width, canvas.height);
};

const drawPath = (
  path: maze.Cell[],
  cellSize: number,
  ctx: CanvasRenderingContext2D,
) => {
  ctx.fillStyle = `${colors.accent}d6`;

  const pathSize = cellSize * pathScale;
  const offset = cellSize / 2;

  for (const cell of path) {
    const x = xFn(cell.col * cellSize + offset);
    const y = yFn(cell.row * cellSize + offset);
    /* ctx.fillRect(x, y, pathSize, pathSize); */
    ctx.beginPath();
    ctx.arc(x, y, pathSize, 0, 2 * Math.PI, false);

    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 0;
    ctx.shadowBlur = 8;
    ctx.shadowColor = colors.primary;
    ctx.fill();
  }
};

const drawTexture = (
  grid: maze.Grid,
  cellSize: number,
  ctx: CanvasRenderingContext2D,
) => {
  const centerCell = maze.cellAt(
    grid,
    Math.floor(grid.numRows / 2),
    Math.floor(grid.numCols / 2),
  );
  const distances = maze.computeDistanceFrom(grid, centerCell);
  const [, max] = maze.max(distances);

  for (const [cell, distance] of distances.cells) {
    const amount = distance / max;
    const color = hexToRgb(
      lerpColor(colors.background, colors.primary, amount),
    );

    const x = xFn(cell.col * cellSize);
    const y = yFn(cell.row * cellSize);

    ctx.fillStyle = `rgba(${color.r}, ${color.g}, ${color.b}, 0.6)`;
    ctx.fillRect(x, y, cellSize, cellSize);
  }
};

const drawMaze = (
  grid: maze.Grid,
  cellSize: number,
  ctx: CanvasRenderingContext2D,
) => {
  ctx.strokeStyle = colors.primary;
  ctx.lineWidth = 2;

  maze.mapCell(grid, cell => {
    const x1 = xFn(cell.col * cellSize);
    const y1 = yFn(cell.row * cellSize);
    const x2 = xFn((cell.col + 1) * cellSize);
    const y2 = yFn((cell.row + 1) * cellSize);

    // north
    if (!cell.north) {
      line(ctx, x1, y1, x2, y1);
    }

    // west
    if (!cell.west) {
      line(ctx, x1, y1, x1, y2);
    }

    // east
    if (!maze.isLinked(cell, cell.east)) {
      line(ctx, x2, y1, x2, y2);
    }

    // south
    if (!maze.isLinked(cell, cell.south)) {
      line(ctx, x1, y2, x2, y2);
    }
  });
};

const useAutoSize = (
  grid: maze.Grid | undefined,
  width: number | undefined,
  height: number | undefined,
): number => {
  const [size, setSize] = React.useState(minCellSize);

  React.useEffect(() => {
    if (grid != null && width != null && height != null) {
      const cellWidthSize = (width - padding * 2) / grid.numCols;
      const cellHeightSize = (height - padding * 2) / grid.numRows;
      setSize(
        Math.floor(
          Math.min(
            Math.max(Math.min(cellWidthSize, cellHeightSize), minCellSize),
            maxCellSize,
          ),
        ),
      );
    }
  }, [width, height, grid]);

  return size;
};

const Maze = () => {
  const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
  const { grid, path, actions, options } = useStore();
  const { width, height } = useCanvasSize(canvasRef);
  const cellSize = useAutoSize(grid, width, height);

  React.useEffect(() => {
    actions.generate();
  }, []);

  React.useEffect(() => {
    const canvas = canvasRef.current;

    if (canvas != null && grid != null) {
      const ctx = canvas.getContext("2d")!;

      configureCanvas(grid, canvas, ctx, width, height, cellSize);

      if (options.showTexture) {
        drawTexture(grid, cellSize, ctx);
      }

      drawMaze(grid, cellSize, ctx);

      if (path != null && options.showLongestPath) {
        drawPath(path, cellSize, ctx);
      }
    }
  }, [width, height, grid, options, cellSize]);

  return <Canvas ref={canvasRef} className="maze" />;
};

export default Maze;
