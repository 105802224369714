export const lerpColor = (a: string, b: string, amount: number): string => {
  const ah = parseInt(a.replace(/#/g, ""), 16);
  const ar = ah >> 16;
  const ag = (ah >> 8) & 0xff;
  const ab = ah & 0xff;
  const bh = parseInt(b.replace(/#/g, ""), 16);
  const br = bh >> 16;
  const bg = (bh >> 8) & 0xff;
  const bb = bh & 0xff;
  const rr = ar + amount * (br - ar);
  const rg = ag + amount * (bg - ag);
  const rb = ab + amount * (bb - ab);

  return (
    "#" + (((1 << 24) + (rr << 16) + (rg << 8) + rb) | 0).toString(16).slice(1)
  );
};

function cutHex(h: string): string {
  return h.charAt(0) == "#" ? h.substring(1, 7) : h;
}

export const hexToRgb = (
  h: string,
): {
  r: number;
  g: number;
  b: number;
} => {
  const r = parseInt(cutHex(h).substring(0, 2), 16);
  const g = parseInt(cutHex(h).substring(2, 4), 16);
  const b = parseInt(cutHex(h).substring(4, 6), 16);

  return {
    r,
    g,
    b,
  };
};
