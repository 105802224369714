import * as React from "react";
import _ from "lodash";

export const useCanvasSize = (
  canvasRef: React.MutableRefObject<HTMLCanvasElement | null>,
): { width: number; height: number } => {
  const [width, setWidth] = React.useState<number>(
    canvasRef.current?.clientWidth ?? 0,
  );
  const [height, setHeight] = React.useState<number>(
    canvasRef.current?.clientHeight ?? 0,
  );

  React.useEffect(() => {
    const listener = () => {
      setWidth(canvasRef.current?.clientWidth ?? 0);
      setHeight(canvasRef.current?.clientHeight ?? 0);
    };
    // listener();
    setTimeout(listener, 0);

    window.onresize = _.debounce(listener, 50);
    return () => {
      window.onresize = null;
    };
  }, []);

  return { width, height };
};
