import create, { StateCreator, SetState, GetState, StoreApi } from "zustand";
import * as maze from "./maze";
import { AlgoTypes } from "./maze";
import _ from "lodash";

export interface MazeOptions {
  numRows: number | null;
  numCols: number | null;
  algo: AlgoTypes;
  showLongestPath: boolean;
  showTexture: boolean;
  autoFit: boolean;
}

export interface State {
  grid?: maze.Grid;
  path?: maze.Cell[];
  options: MazeOptions;
}

export interface Actions {
  generate: () => void;
  updateOptions: (options: MazeOptions) => void;
}

type StoreType = State & { actions: Actions };

type Middleware = (
  config: StateCreator<StoreType>,
) => (
  set: SetState<StoreType>,
  get: GetState<StoreType>,
  api: StoreApi<StoreType>,
) => StoreType;

const log: Middleware = config => (set, get, api) =>
  config(
    args => {
      set(args);
      console.log("new state", get());
    },
    get,
    api,
  );

const saveOptions: Middleware = config => (set, get, api) =>
  config(
    args => {
      set(args);
      const newState: State = get();
      setTimeout(() => {
        localStorage.setItem("maze-options", JSON.stringify(newState.options));
      }, 0);
    },
    get,
    api,
  );

const getInitialOptions = (): MazeOptions => {
  let initialOptions: MazeOptions = {
    numRows: 15,
    numCols: 15,
    algo: "sidewinder",
    showLongestPath: false,
    showTexture: false,
    autoFit: true,
  };

  const item =
    typeof window !== "undefined" ? localStorage.getItem("maze-options") : null;

  if (item !== null) {
    try {
      const savedOptions = JSON.parse(item) as Partial<MazeOptions>;
      return {
        ...initialOptions,
        ...savedOptions,
      };
    } catch (e) {}
  }

  return initialOptions;
};

const optionsRequiringRegenerate: Array<keyof MazeOptions> = [
  "algo",
  "numRows",
  "numCols",
];

const isValidGridDim = (value: any): boolean =>
  typeof value === "number" && value > 0 && !_.isNaN(value) && !_.isNil(value);

export const [useStore, api] = create<StoreType>(
  log(
    saveOptions((set, get) => ({
      options: getInitialOptions(),
      actions: {
        generate: () => {
          const { numRows, numCols, algo } = get().options;

          if (
            !isValidGridDim(numRows) ||
            !isValidGridDim(numCols) ||
            !numRows ||
            !numCols
          ) {
            set({ grid: undefined, path: undefined });
            return;
          }

          const grid = maze.createGrid(numRows, numCols);

          if (algo === "sidewinder") {
            maze.performSidewinder(grid);
          } else if (algo === "binary") {
            maze.performBinaryTree(grid);
          }

          const { path } = maze.findLongestPath(grid);

          set({ grid, path });
        },
        updateOptions: newOptions => {
          const oldOptions = get().options;
          set({ options: newOptions });

          let regrid = false;
          Object.keys(newOptions).forEach(key => {
            if (
              !oldOptions.hasOwnProperty(key) ||
              newOptions[key] !== oldOptions[key]
            ) {
              if (optionsRequiringRegenerate.includes(key as any)) {
                regrid = true;
              }
            }
          });

          if (regrid) {
            api.getState().actions.generate();
          }
        },
      },
    })),
  ),
);
