import { Grid, link, Cell, mapCell } from "..";

export const performBinaryTree = (grid: Grid) => {
  mapCell(grid, cell => {
    const neighbours: Cell[] = [];

    if (cell.north) {
      neighbours.push(cell.north);
    }

    if (cell.east) {
      neighbours.push(cell.east);
    }

    const index = Math.floor(Math.random() * neighbours.length);
    const n = neighbours[index];

    if (n) {
      link(cell, neighbours[index]);
    }
  });
};
