import { Cell } from "./types";

export const newCell = (row: number, col: number): Cell => ({
  row,
  col,
  links: new Set(),
});

export const link = (cell1: Cell, cell2: Cell, bidi = true) => {
  cell1.links.add(cell2);

  if (bidi) {
    cell2.links.add(cell1);
  }
};

export const unlink = (cell1: Cell, cell2: Cell, bidi = true) => {
  cell1.links.delete(cell2);

  if (bidi) {
    cell2.links.delete(cell1);
  }
};

export const isLinked = (
  cell1: Cell | undefined,
  cell2: Cell | undefined,
): boolean => {
  if (cell1 == null || cell2 == null) {
    return false;
  }

  return cell1.links.has(cell2);
};

export const neighbours = (cell: Cell): Cell[] =>
  [cell.north, cell.south, cell.east, cell.west].filter(
    c => c != null,
  ) as Cell[];
