import { Grid, link, Cell, mapRow, randInt, randItem } from "..";

export const performSidewinder = (grid: Grid) => {
  mapRow(grid, rowList => {
    let run: Cell[] = [];

    rowList.map(cell => {
      run.push(cell);

      const atEasternBoundary = cell.east == null;
      const atNorthernBoundary = cell.north == null;

      const shouldCloseOut =
        atEasternBoundary || (!atNorthernBoundary && randInt(2) == 0);

      if (shouldCloseOut) {
        const member = randItem(run);

        if (member.north) {
          link(member, member.north);
        }

        run = [];
      } else if (cell.east) {
        link(cell, cell.east);
      }
    });
  });
};
