import {
  Grid,
  Cell,
  Distances,
  newCell,
  isLinked,
  computeDijkstraMap,
  computePathTo,
  max,
} from "./";
import _ from "lodash";

export const createGrid = (numRows: number, numCols: number): Grid => {
  // create the cells
  const cells = _.range(numRows).map(row =>
    _.range(numCols).map(col => newCell(row, col)),
  );

  // setup neighbours
  for (let row = 0; row < numRows; row += 1) {
    for (let col = 0; col < numCols; col += 1) {
      const cell = _.get(cells, [row, col]);

      cell.north = _.get(cells, [row - 1, col]);
      cell.south = _.get(cells, [row + 1, col]);
      cell.east = _.get(cells, [row, col + 1]);
      cell.west = _.get(cells, [row, col - 1]);
    }
  }

  return {
    numRows,
    numCols,
    cells,
    distances: new Map(),
  };
};

export const cellAt = (grid: Grid, row: number, col: number): Cell => {
  if (row < 0 || col < 0 || row >= grid.numRows || col >= grid.numCols) {
    throw new Error(`${row}, ${col} is out of bounds`);
  }
  return _.get(grid.cells, [row, col]);
};

export const randomCell = (grid: Grid): Cell => {
  const row = Math.floor(Math.random() * grid.numRows);
  const col = Math.floor(Math.random() * grid.numCols);
  return grid.cells[row][col];
};

export const mapRow = (grid: Grid, fn: (rowList: Cell[]) => void) =>
  grid.cells.map(fn);

export const mapCell = (grid: Grid, fn: (cell: Cell) => void) =>
  mapRow(grid, rowList => rowList.map(fn));

export const computeDistanceFrom = (grid: Grid, root: Cell): Distances => {
  if (!grid.distances.has(root)) {
    const distances = computeDijkstraMap(root);
    grid.distances.set(root, distances);
  }

  return grid.distances.get(root)!;
};

export const findShortestPath = (
  grid: Grid,
  start: Cell,
  end: Cell,
): Cell[] => {
  const distances = computeDistanceFrom(grid, end);
  const path = computePathTo(start, distances);
  return path;
};

export const findLongestPath = (
  grid: Grid,
): { path: Cell[]; distances: Distances } => {
  const start = cellAt(grid, 0, 0);
  const distances = computeDistanceFrom(grid, start);
  const [newStart] = max(distances);

  const newDistances = computeDistanceFrom(grid, newStart);
  const [goal] = max(newDistances);

  const path = findShortestPath(grid, newStart, goal);

  return {
    path,
    distances: newDistances,
  };
};

export const gridToString = (grid: Grid, printDistancesFrom?: Cell): string => {
  let output = `+${_.repeat("---+", grid.numCols)}\n`;

  const distances: Distances | undefined =
    printDistancesFrom != null
      ? computeDistanceFrom(grid, printDistancesFrom)
      : undefined;

  mapRow(grid, rowList => {
    let top = "|";
    let bottom = "+";

    rowList.map(cell => {
      const center = distances != null ? distances.cells.get(cell) : " ";
      let body = ` ${center} `;
      const easyBoundary = isLinked(cell, cell.east) ? " " : "|";
      top += body + easyBoundary;

      const southBoundary = isLinked(cell, cell.south) ? "   " : "---";
      const corner = "+";
      bottom += southBoundary + corner;
    });

    output += top + "\n";
    output += bottom + "\n";
  });

  return output;
};
