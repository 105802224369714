import * as React from "react";
import styled from "styled-components";
import css from "@styled-system/css";
import { colors } from "../styles";

export interface Props {
  title: string;
  options: Array<{ text: string; value: string }>;
  selectedValue: string;
  onSelect: (value: string) => void;
}

const StyledSelect = styled.div(css({}));

const Title = styled.span(
  css({
    pb: 1,
    fontSize: 2,
  }),
);

const Options = styled.div(
  css({
    pt: 2,
  }),
);

const Option = styled.div<{ selected: boolean }>(props =>
  css({
    fontSize: 2,
    cursor: "pointer",
    fontFamily: "monospace",

    opacity: props.selected ? 1.0 : 0.7,

    textShadow: props.selected
      ? `0 0 2px ${colors.accent}, 0 0 5px ${colors.accent}`
      : "",

    transition: "all 250ms ease-in-out",

    "&:hover": {
      opacity: 1.0,

      ".arrow": {
        opacity: 1.0,
      },
    },
  }),
);

const Arrow = styled.span(
  css({
    display: "inline-block",
    ml: 2,
    opacity: 0.0,

    transition: "opacity 250ms ease-in-out",
  }),
);

const Select: React.FC<Props> = props => (
  <StyledSelect>
    <Title>{props.title}</Title>

    <Options>
      {props.options.map(({ text, value }) => (
        <Option
          key={value}
          selected={props.selectedValue === value}
          onClick={() => {
            if (value !== props.selectedValue) {
              props.onSelect(value);
            }
          }}
        >
          {text}
          <Arrow className="arrow">{"<--"}</Arrow>
        </Option>
      ))}
    </Options>
  </StyledSelect>
);

export default Select;
