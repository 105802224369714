import css from "@styled-system/css";
import styled from "styled-components";
import { colors } from "../styles";

export const FormGroup = styled.div<{ inline?: boolean }>(props =>
  props.inline
    ? css({
        display: "flex",
        alignItems: "center",
        flexDirection: "row-reverse",
        justifyContent: "space-between",
      })
    : css({
        py: 2,
        label: {
          display: "block",
          pb: 1,
          fontSize: 2,
        },
      }),
);

export const Label = styled.label(css({}));
