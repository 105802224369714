import css from "@styled-system/css";
import styled from "styled-components";
import { colors } from "../styles";

const Input = styled.input(
  css({
    appearance: "none",
    display: "inline-block",
    m: 0,
    px: 2,
    py: 1,
    color: "text",
    backgroundColor: "background",
    border: "solid 2px",
    borderColor: "primary",
    borderRadius: 2,
    fontSize: 2,

    transition: "all 250ms ease-in-out",

    "&:focus": {
      textShadow: `0 0 2px ${colors.accent}, 0 0 5px ${colors.accent}`,
      boxShadow: `0 0 2px ${colors.accent}, 0 0 5px ${colors.accent}`,
    },
  }),
);

export default Input;
