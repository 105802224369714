import css from "@styled-system/css";
import styled from "styled-components";
import { colors } from "../styles";

const Button = styled.button(
  css({
    appearance: "none",
    display: "inline-block",
    width: "100%",
    backgroundColor: "transparent",
    color: "primary",
    textAlign: "center",
    textDecoration: "none",
    mx: 0,
    my: 1,
    px: 2,
    py: 1,
    border: "solid 2px",
    borderColor: "primary",
    borderRadius: 2,
    cursor: "pointer",
    fontSize: 2,

    transition: "all 250ms ease-in-out",

    "&:hover": {
      borderColor: "primary",
      textShadow: `0 0 2px ${colors.accent}, 0 0 5px ${colors.accent}`,
      boxShadow: `0 0 2px ${colors.accent}, 0 0 5px ${colors.accent}`,
    },
  }),
);

export default Button;
